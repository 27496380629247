<template>
  <MissionsAsideLayout
    id="missions"
    ref="missions"
    class="missions"
  >
    <template
      v-slot:header
    >
      <MissionsListHeader />
    </template>

    <MissionsList
      v-if="missions.length"
      class="tw-min-h-0"
      ref="missionsList"
      data-test="list"
    />

    <MissionsEmptyState
      v-if="!$wait.is('fetching missions') && !missions.length"
      data-test="empty"
    />

    <CtkLoadingLayer
      v-if="$wait.is('fetching missions') && isAfterTimeout"
      data-test="loading"
    >
      {{ $t('app.loading') }}
    </CtkLoadingLayer>

    <change-owner-dialog
      v-model="changeOwnerDialog"
    />
  </MissionsAsideLayout>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import MissionSections from './_subs/MissionSections.vue'
  import MissionsList from './_subs/MissionsList/index.vue'
  import CtkSideBar from '@/components/CtkSideBar/index.vue'
  import MissionDetail from './_subs/MissionDetail/index.vue'
  import FinalStepDialogContent from './_subs/finalStepDialogContent.vue'
  import ChangeOwnerDialog from '@/views/Carriers/Missions/components/ChangeOwnerDialog/index.vue'

  import { EventBus } from '@/services/EventBus'
  import store from '@/store'

  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'
  import MissionsEmptyState from '@/views/Carriers/Missions/components/MissionsEmptyState/index.vue'
  import MissionsAsideLayout from '../components/MissionsAsideLayout/index.vue'
  import MissionsListHeader from './_subs/MissionsList/_subs/MissionsListHeader/index.vue'
  import { LOADING_TIMEOUT_IN_MS } from '@/composables/constants'

  export default defineComponent({
    name: 'Missions',
    components: {
      MissionSections,
      MissionsList,
      CtkSideBar,
      MissionDetail,
      FinalStepDialogContent,
      ChangeOwnerDialog,
      CtkLoadingLayer,
      MissionsEmptyState,
      MissionsListHeader,
      MissionsAsideLayout
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      if (to.name !== 'Mission') {
        if (
          (store.getters.isUserMono && to.params.type === 'company') ||
          (store.getters.isUserDispatcher && to.params.type === 'me')
        ) {
          return next({ name: 'MissionsRouter' })
        }
      }
      next()
    },
    data () {
      return {
        stateInterval: null,
        askToggle: false,
        iframeNotLoaded: true,
        fileChecking: true,
        isAnImage: null,
        finalStepDialog: false,
        changeOwnerDialog: false,
        missionState: undefined,
        isAfterTimeout: false
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getStoredMissions',
        'getCurrentMissionStatus',
        'getCurrentMissionUuid',
        'getMissionsFilters'
      ]),
      ...mapGetters([
        'isUserMono',
        'isUserDispatcher'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('ui', [
        'isSidebarOpen'
      ]),
      missions () {
        return this.getStoredMissions
      }
    },
    watch: {
      $route: {
        handler: function (to) {
          /**
           * When the navigation comes from the mission/:uuid view to
           * another mission list state, ensure to remove the highlighted mission
           */
          if (to.name === 'Missions') {
            this.setMissionUuidToShow(null)
          }

          /**
           * Before switching to another route, reset the mission filters
           */
          this.resetMissionsFilters()

          if (to.name === 'Mission') {
            this.fetchMission()
          } else {
            this.fetchMissions(to, to.params.state)
          }
        },
        deep: true
      }
    },
    created () {
      if (this.$route.name === 'Mission') {
        this.fetchMission()
      } else {
        this.$store.commit('missions/RESET_CURRENT_MISSION')

        this.resetTimeout()
        this.startFetchingMissionsWaiter()
        this.missionState = this.$route.params.state
      }

      /**
       * Fetch metrics
       */
      this.fetchMetrics()

      /**
       * Fetch the missions
       */
      this.stateInterval = setInterval(() => {
        if (typeof this.missionState !== 'undefined') {
          clearInterval(this.stateInterval)
          this.$store.commit('missions/SET_CURRENT_MISSION_STATUS', this.missionState)
          this.fetchMissions(this.$route, this.missionState)
        }
      }, 300)
    },
    mounted () {
      // Responsive side menu toggle
      window.addEventListener('resize', this.toggleSideMenu)

      EventBus.$on('missions:sidebar-visible', (value) => {
        this.setSidebarOpen(value)
      })

      EventBus.$on('launch-final-step', () => {
        this.finalStepDialog = true
      })
      EventBus.$on('open-change-owner-dialog', () => {
        this.changeOwnerDialog = true
      })

      EventBus.$on('missions:refresh-list', () => {
        this.fetchMissions(this.$route, this.$route.params.state)
      })

      this.resetTimeout()
    },
    beforeDestroy () {
      if (this.stateInterval) {
        clearInterval(this.stateInterval)
      }

      const events = ['launch-final-step', 'open-change-owner-dialog', 'missions:refresh-list']
      events.forEach(event => {
        EventBus.$off(event)
      })

      window.removeEventListener('resize', this.toggleSideMenu)
    },
    methods: {
      ...mapActions([
        'setAppReady'
      ]),
      ...mapActions('ui', [
        'toggleSidebar',
        'setSidebarOpen'
      ]),
      ...mapActions('missions', [
        'getMissions',
        'getMetrics',
        'setCurrentMissionStatus',
        'getMission',
        'resetMissionsFilters',
        'setMissionUuidToShow'
      ]),
      startFetchingMissionsWaiter () {
        this.resetTimeout()
        this.$wait.start('fetching missions')
      },
      resetTimeout () {
        this.isAfterTimeout = false
        setTimeout(() => {
          this.isAfterTimeout = true
        }, LOADING_TIMEOUT_IN_MS)
      },
      fetchMetrics () {
        this.getMetrics({
          type: 'company'
        })
          .catch(err => {
            if (!err.response) return

            console.log('error get metrics', err)
          })
      },
      fetchMissions (route, state) {
        this.setCurrentMissionStatus(state)

        this.resetTimeout()
        this.startFetchingMissionsWaiter()
        this.getMissions({
          status: state,
          limit: 30,
          type: route.params.type || (this.isUserMono ? 'me' : 'company')
        })
          .catch(() => {})
          .finally(() => {
            this.$wait.end('fetching missions')
            this.setAppReady(true)
          })
      },
      fetchMission () {
        const missionUuid = this.$route.params.uuid
        this.$wait.start('fetching mission')

        this.getMission(missionUuid)
          .then(response => {
            const mission = response.data
            const items = [mission]

            const searchMissionMeta = {
              item_count: items.length,
              pagination: {
                current_page: 1,
                page_count: 1,
                page_size: 30
              }
            }

            this.$store.commit('missions/SET_MISSIONS', {
              items
            })

            this.$store.commit('missions/SET_MISSIONS_META', {
              meta: searchMissionMeta
            })

            this.fetchMetrics()
          })
          .catch(() => {
            this.$router.replace({
              name: 'MissionsRouter'
            }).catch(() => {})
          })
          .finally(() => {
            this.$wait.end('fetching mission')
            this.setAppReady(true)
          })
      },
      toggleSideMenu () {
        const width = document.documentElement.clientWidth
        if (!this.askToggle && width <= 1024) {
          this.askToggle = true
          this.setSidebarOpen(false)
        }
      }
    }
  })
</script>
